import React, { useContext } from "react";
import { EGControlButtonProps, EGGameStateAction } from "../../types";
import { GameStateDispatchContext } from "../../contexts/GameStateContext";
import "./EGControlButton.scss";

export const EGControlButton: React.FC<EGControlButtonProps> = ({
    label,
    actionType,
    payload,
}) => {
    const dispatch = useContext(GameStateDispatchContext);
    const action: EGGameStateAction = {
        type: actionType,
        payload: payload,
    };
    const handleButtonClick = () => {
        dispatch(action);
    };

    return (
        <button className="eg-control-button" onClick={handleButtonClick}>
            {label}
        </button>
    );
};
