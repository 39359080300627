import { cityPercentOffsets } from "./constants/cityPercentOffsets";
import { CityProps } from "../types";
import { cityStatePermutations } from "./constants/cityStatePermutations";

export const getCityProps = (): CityProps[] => {
    const cityProps: CityProps[] = [];
    for (const city in cityPercentOffsets)
        cityProps.push({
            name: city,
            xPercent: cityPercentOffsets[city].xPercent,
            yPercent: cityPercentOffsets[city].yPercent,
            permutation: cityStatePermutations[city].permutation,
        });

    return cityProps;
}