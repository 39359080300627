interface CityStatePermutationsTable {
    [city: string]: { permutation: number[] };
}

export const cityStatePermutations: CityStatePermutationsTable = {
    Moscow: {
        permutation: [1, 2, 3, 4],
    },
    Leningrad: {
        permutation: [1, 2, 4, 3],
    },
    Stalingrad: {
        permutation: [1, 3, 2, 4],
    },
    Kiev: {
        permutation: [1, 3, 4, 2],
    },
    Odessa: {
        permutation: [1, 4, 2, 3],
    },
    Gorki: {
        permutation: [1, 4, 3, 2],
    },
    Ufa: {
        permutation: [2, 1, 3, 4],
    },
    Smolensk: {
        permutation: [2, 1, 4, 3],
    },
    Kurgan: {
        permutation: [2, 4, 1, 3],
    },
    Omsk: {
        permutation: [2, 4, 3, 1],
    },
    Novosibirsk: {
        permutation: [2, 3, 1, 4],
    },
    Krasnoyarsk: {
        permutation: [2, 3, 4, 1],
    },
    Tashkent: {
        permutation: [3, 1, 2, 4],
    },
    Irkutsk: {
        permutation: [3, 1, 4, 2],
    },
    Chita: {
        permutation: [3, 2, 1, 4],
    },
    Yakutsk: {
        permutation: [3, 2, 4, 1],
    },
    Samara: {
        permutation: [3, 4, 1, 2],
    },
    Vladivostok: {
        permutation: [3, 4, 2, 1],
    },
    Murmansk: {
        permutation: [4, 1, 2, 3],
    },
    Frunze: {
        permutation: [4, 1, 3, 2],
    },
    'Alma-Ata': {
        permutation: [4, 2, 1, 3],
    },
    Semipalatinsk: {
        permutation: [4, 2, 3, 1],
    },
    Tbilisi: {
        permutation: [4, 3, 1, 2],
    },
    Minsk: {
        permutation: [4, 3, 2, 1],
    },
};
