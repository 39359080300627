import { useContext } from "react";
import { EGControlButtonContainerProps } from "../../types";
import "./EGControlButtonContainer.scss";
import { EGControlButtonGroup } from "./EGControlButtonGroup";
import { GameStateContext } from "../../contexts/GameStateContext";
import { buttonGroups } from "../../helpers/initializeGameState";

export const EGControlButtonContainer: React.FC<
    EGControlButtonContainerProps
> = () => {
    const gameState = useContext(GameStateContext);
    const buttonGroup = buttonGroups[gameState.currentCity];
    return (
        <section className="eg-button-container">
            {gameState.gameOver
                ? (
                    <>
                        <h2 className="game-over-text">Game over!</h2>
                        <ul className="game-stats">
                            <li>Total Turns: {gameState.turnNumber}</li>
                            <li>Total Active Pursuers: {gameState.policeLocations.length}</li>

                        </ul>
                    </>
                )
                : (<EGControlButtonGroup buttonGroup={buttonGroup.buttonGroup} />)
            }
        </section>
    );
};
