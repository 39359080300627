import { compareArrays } from "@escape-game/processing-module/helpers/compareArrays";

export function updateCaptureCounter(currCounter: number, currentPermutation: number[], policeLocations: number[][]): number {
    for (const location of policeLocations) {
        if (compareArrays(currentPermutation, location)) {
            return currCounter + 1;
        }
    }
    return 0;
}
