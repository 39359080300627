import { compareArrays } from "@escape-game/processing-module/helpers/compareArrays";
import {
    EGControlButtonProps,
    EGButtonActionType,
    EGControlButtonContainerProps,
} from "../types";
import { cityTrainShuffledPermutations } from "./constants/cityTrainPermutations";
import { escapeCities } from "./constants/escapeCities";

export const getButtonGroups = (): EGControlButtonContainerProps => {
    const buttonGroups: EGControlButtonContainerProps = {};

    Object.keys(cityTrainShuffledPermutations).forEach((city) => {
        const permutations = cityTrainShuffledPermutations[city].permutations;
        const buttonGroup: EGControlButtonProps[] = permutations.map(
            (permutation, index) => ({
                label: getLabel(permutation),
                actionType: EGButtonActionType.PERMUTE,
                payload: permutation,
            }));

        if (escapeCities.includes(city)) {
            const escapeButton: EGControlButtonProps = {
                label: getLabel(),
                actionType: EGButtonActionType.ESCAPE,
                payload: [1, 2, 3, 4],
            };
            buttonGroup.splice((Math.random() * buttonGroup.length), 0, escapeButton);
        }

        buttonGroups[city] = { buttonGroup };
    });

    return buttonGroups;
};

function getLabel(permutation?: number[]) {
    const labels = ["Train", "Truck"];
    const randomIndex = Math.floor(Math.random() * labels.length);

    if ((permutation) && compareArrays(permutation, [1,2,3,4])) {
        return "Hide"
    }

    return labels[randomIndex]
}