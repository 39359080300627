import { ControlButtonProps } from "@escape-game/control-module/types";
import { GameState } from "@escape-game/processing-module/types";

//Control
export enum EGButtonActionType {
    PERMUTE = "permute",
    ESCAPE = "escape",
}

export interface EGControlButtonProps extends ControlButtonProps {
    label: string;
    actionType: EGButtonActionType;
    payload: number[];
}

export interface EGControlButtonGroupProps {
    buttonGroup: EGControlButtonProps[];
}

export interface EGControlButtonContainerProps {
    [city: string]: EGControlButtonGroupProps;
}

//Display
export interface MapProps {
    cities: CityProps[];
}

export interface CityProps {
    name: string;
    xPercent: number;
    yPercent: number;
    permutation: number[];
}

//Processing
export interface EGGameState extends GameState {
    currentCity: string;
    currentPermutation: number[];
    turnNumber: number;
    policeLocations: number[][];
    captureCounter: number;
    gameOver: boolean;
    gameResultText: string;
}

export interface EGGameStateAction {
    type: EGButtonActionType;
    payload: number[];
}