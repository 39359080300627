import { EGControlButtonContainer } from "./Control/EGControlButtonContainer";
import { HomepageLink } from "./Display/HomepageLink";
import { StatusPanel } from "./Display/StatusPanel";
import { Map } from "./Display/Map";
import "./Game.scss";
import { initialGameState } from "../helpers/initializeGameState";
import { GameStateContext, GameStateDispatchContext } from "../contexts/GameStateContext";
import { useReducer } from "react";
import { gameStateReducer } from "../reducers/gameStateReducer";

export const Game = () => {
    const [gameState, dispatch] = useReducer(
        gameStateReducer,
        initialGameState
    );

    return (
        <GameStateContext.Provider value={gameState}>
            <GameStateDispatchContext.Provider value={dispatch}>
                <div className="eg-game">
                    <div className="eg-game-header-container">
                        <HomepageLink />
                        <StatusPanel />
                    </div>
                    <div className="eg-game-primary-container">
                        <Map />
                        <EGControlButtonContainer />
                    </div>
                </div>
            </GameStateDispatchContext.Provider>
        </GameStateContext.Provider>
    );
};