interface IntelText {
    [captureCounter: number]: string;
}

export const intelText: IntelText = {
    0:
        "You seem safe for now.",
    1:
        "The police are closing in.",
    2:
        "They're hot on your tail, be careful",
    3:
        "You are never heard from again."
}