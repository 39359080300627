import { shufflePermutationsArray } from "@escape-game/processing-module/helpers/shufflePermutations";

interface CityTrainPermutationsTable {
    [city: string]: { permutations: number[][] };
}

const cityTrainPermutations: CityTrainPermutationsTable = {
    Moscow: {
        permutations: [
            [1, 2, 3, 4],
            [1, 2, 4, 3],
            [1, 3, 2, 4],
            [1, 4, 3, 2],
            [2, 1, 3, 4],
            [2, 4, 1, 3],
            [2, 4, 3, 1],
            [2, 3, 1, 4],
            [2, 3, 4, 1],
            [4, 3, 1, 2],
        ],
    },
    Leningrad: {
        permutations: [
            [1, 2, 3, 4],
            [1, 2, 4, 3],
            [1, 4, 2, 3],
            [1, 3, 4, 2],
            [1, 3, 2, 4],
            [4, 1, 3, 2],
            [3, 1, 4, 2],
            [3, 2, 1, 4],
        ],
    },
    Stalingrad: {
        permutations: [
            [1, 2, 3, 4],
            [1, 3, 2, 4],
            [1, 3, 4, 2],
            [2, 1, 4, 3],
            [3, 2, 1, 4],
            [2, 1, 4, 3],
            [2, 3, 1, 4],
            [2, 3, 4, 1],
        ],
    },
    Kiev: {
        permutations: [
            [1, 2, 3, 4],
            [1, 4, 2, 3],
            [1, 2, 4, 3],
            [4, 3, 1, 2],
            [4, 2, 3, 1],
            [3, 2, 4, 1],
        ],
    },
    Odessa: {
        permutations: [
            [1, 2, 3, 4],
            [1, 3, 2, 4],
            [1, 4, 3, 2],
            [4, 1, 2, 3],
            [2, 1, 4, 3],
            [2, 3, 1, 4],
        ],
    },
    Gorki: {
        permutations: [
            [1, 2, 3, 4],
            [1, 4, 3, 2],
            [4, 2, 3, 1],
            [3, 2, 1, 4],
            [2, 4, 3, 1],
            [2, 3, 1, 4],
        ],
    },
    Ufa: {
        permutations: [
            [1, 2, 3, 4],
            [2, 1, 3, 4],
            [1, 4, 2, 3],
            [1, 3, 2, 4],
            [1, 3, 4, 2],
            [3, 1, 4, 2],
        ],
    },
    Smolensk: {
        permutations: [
            [1, 2, 3, 4],
            [2, 4, 1, 3],
            [2, 4, 3, 1],
            [4, 2, 1, 3],
            [4, 3, 2, 1],
            [3, 4, 1, 2],
        ],
    },
    Kurgan: {
        permutations: [
            [1, 2, 3, 4],
            [3, 1, 4, 2],
            [1, 3, 4, 2],
            [1, 4, 3, 2],
            [1, 4, 2, 3],
            [4, 1, 2, 3],
        ],
    },
    Omsk: {
        permutations: [
            [1, 2, 3, 4],
            [4, 1, 3, 2],
            [1, 2, 4, 3],
            [1, 3, 2, 4],
            [2, 4, 1, 3],
            [2, 3, 4, 1],
        ],
    },
    Novosibirsk: {
        permutations: [
            [1, 2, 3, 4],
            [3, 1, 2, 4],
            [3, 2, 1, 4],
            [2, 1, 3, 4],
            [2, 1, 4, 3],
            [4, 2, 3, 1],
        ],
    },
    Krasnoyarsk: {
        permutations: [
            [1, 2, 3, 4],
            [4, 1, 2, 3],
            [1, 3, 4, 2],
            [1, 3, 2, 4],
            [3, 4, 1, 2],
            [3, 2, 4, 1],
        ],
    },
    Tashkent: {
        permutations: [
            [1, 2, 3, 4],
            [2, 1, 4, 3],
            [3, 2, 4, 1],
            [1, 2, 4, 3],
            [4, 2, 1, 3],
            [4, 1, 3, 2],
        ],
    },
    Irkutsk: {
        permutations: [
            [1, 2, 3, 4],
            [2, 1, 4, 3],
            [1, 4, 2, 3],
            [3, 2, 1, 4],
            [3, 1, 4, 2],
        ],
    },
    Chita: {
        permutations: [
            [1, 2, 3, 4],
            [3, 1, 2, 4],
            [2, 1, 3, 4],
            [1, 3, 4, 2],
            [1, 2, 4, 3],
        ],
    },
    Yakutsk: {
        permutations: [
            [1, 2, 3, 4],
            [4, 1, 2, 3],
            [2, 4, 1, 3],
            [2, 1, 4, 3],
            [1, 2, 4, 3],
        ],
    },
    Samara: {
        permutations: [
            [1, 2, 3, 4],
            [3, 1, 2, 4],
            [3, 2, 1, 4],
            [4, 3, 2, 1],
            [2, 1, 3, 4],
        ],
    },
    Vladivostok: {
        permutations: [
            [1, 2, 3, 4],
            [4, 3, 2, 1],
            [4, 2, 3, 1],
        ],
    },
    Murmansk: {
        permutations: [
            [1, 2, 3, 4],
            [3, 1, 4, 2],
            [3, 4, 1, 2],
            [1, 4, 2, 3],
        ],
    },
    Frunze: {
        permutations: [
            [1, 2, 3, 4],
            [2, 4, 3, 1],
            [2, 4, 1, 3],
            [2, 3, 1, 4],
            [3, 2, 1, 4],
            [1, 3, 4, 2],
        ],
    },
    'Alma-Ata': {
        permutations: [
            [1, 2, 3, 4],
            [3, 2, 1, 4],
            [3, 4, 2, 1],
            [3, 1, 2, 4],
            [4, 3, 1, 2],
        ],
    },
    Semipalatinsk: {
        permutations: [
            [1, 2, 3, 4],
            [4, 1, 3, 2],
            [2, 1, 3, 4],
            [3, 1, 4, 2],
            [1, 3, 4, 2],
        ],
    },
    Tbilisi: {
        permutations: [
            [1, 2, 3, 4],
            [3, 4, 2, 1],
            [3, 1, 2, 4],
            [4, 1, 2, 3],
            [2, 4, 1, 3],
            [2, 1, 3, 4],
        ],
    },
    Minsk: {
        permutations: [
            [1, 2, 3, 4],
            [4, 2, 1, 3],
            [3, 4, 1, 2],
            [2, 4, 3, 1],
            [1, 4, 2, 3],
        ],
    },
};

export const cityTrainShuffledPermutations: CityTrainPermutationsTable =
    Object.fromEntries(
        Object.entries(cityTrainPermutations).map(([city, data]) => [
            city,
            {
                permutations: shufflePermutationsArray(data.permutations),
            },
        ])
    );
