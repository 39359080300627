import { useContext } from "react";
import "./StatusText.scss";
import { GameStateContext } from "../../contexts/GameStateContext";
import { intelText } from "../../helpers/constants/intelText";

export const StatusText = () => {
    const gameState = useContext(GameStateContext);
    return (
        <div className="status-container">
            <p className="turn-number">
                Day {gameState.turnNumber}
            </p>
            <p className="status-text">
                Intel: {intelText[gameState.captureCounter]}
            </p>
        </div>
    );
};
