import { City } from "./City";
import "./Map.scss";
import { getCityProps } from "../../helpers/getCityProps";

export const Map = () => {
    const cityProps = getCityProps();

    const cities = cityProps.map((city) => (
        <City
            key={city.name}
            name={city.name}
            xPercent={city.xPercent}
            yPercent={city.yPercent}
            permutation={city.permutation}
        />
    ))

    return (
        <section className="map">
            <img className="ussr-img" src="/images/map_prod.webp" alt="map" />
            {cities}
        </section>
    );
};