import { generateRandomPermutation } from "@escape-game/processing-module/helpers/generateRandomPermutation"
import { maxPolice, turnsUntilIncrease } from "./constants/policeRules";

export const movePolice = (policeLocations: number[][], turnNumber: number): number[][] => {
    const newPoliceLocations = policeLocations.map((location) => {
        return generateRandomPermutation(location)
    })

    if (turnNumber % turnsUntilIncrease === 0 && newPoliceLocations.length < maxPolice) {
        newPoliceLocations.push([1, 2, 3, 4]);
    }

    return newPoliceLocations
}