import { EGControlButtonContainerProps, EGGameState } from "../types";
import { getButtonGroups } from "./getButtonGroups";

function initializeGameState() {
    const initialGameState: EGGameState = {
        currentCity: "Moscow",
        currentPermutation: [1, 2, 3, 4],
        turnNumber: 1,
        policeLocations: [[1,2,3,4], [1,2,3,4], [1,2,3,4]],
        captureCounter: 1,
        gameOver: false,
        gameResultText: "",
    };

    const buttonGroups: EGControlButtonContainerProps = getButtonGroups();

    return { buttonGroups, initialGameState };
}

export const { buttonGroups, initialGameState } = initializeGameState();