import { useContext } from "react";
import { GameStateContext } from "../../contexts/GameStateContext";
import { CityProps } from "../../types";
import "./City.scss";

export const City = ({
    name,
    xPercent,
    yPercent,
    permutation
}: CityProps) => {
    const positionStyle = {
        top: `${yPercent}%`,
        left: `${xPercent}%`,
    };

    const gameState = useContext(GameStateContext);

    const activeStyle =
        JSON.stringify(gameState.currentPermutation) ===
        JSON.stringify(permutation)
            ? {
                  backgroundColor: "black",
              }
            : {
                  backgroundColor: "white",
              };


    return (
        <div className="eg-city" style={positionStyle}>
            <div className="eg-city-dot" style={activeStyle}></div>
        </div>
  )
}