import multiplyPermutations from "@escape-game/processing-module/helpers/multiplyPermutations";
import { EGButtonActionType, EGGameState, EGGameStateAction } from "../types";
import { getCityName } from "../helpers/getCityName";
import { movePolice } from "../helpers/movePolice";
import { updateCaptureCounter } from "../helpers/updateCaptureCounter";

function gameStateReducer(state: EGGameState, action: EGGameStateAction) {
    const newTurnNumber = state.turnNumber + 1;
    switch (action.type) {
        case EGButtonActionType.ESCAPE:
            return {
                ...state,
                gameOver: true,
                gameResultText: "You win!"
            }

        case EGButtonActionType.PERMUTE:
            const newPermutation = multiplyPermutations(
                state.currentPermutation,
                action.payload
            );
            const newPoliceLocations = movePolice(state.policeLocations, state.turnNumber);
            const newCity = getCityName(newPermutation);
            const newCaptureCounter = updateCaptureCounter(state.captureCounter, newPermutation, newPoliceLocations);
            if (newCaptureCounter >= 3) {
                return {
                    ...state,
                    turnNumber: newTurnNumber,
                    gameOver: true,
                    gameResultText: "You were captured."
                }
            }
            return {
                ...state,
                currentCity: newCity,
                currentPermutation: newPermutation,
                turnNumber: newTurnNumber,
                policeLocations: newPoliceLocations,
                captureCounter: newCaptureCounter,
            };

        default:
            return state;
    }
}

export { gameStateReducer };
