import { EGControlButtonGroupProps, EGControlButtonProps } from "../../types";
import { EGControlButton } from "./EGControlButton";

export const EGControlButtonGroup: React.FC<EGControlButtonGroupProps> = ({
    buttonGroup,
}) => {
    return (
        <div className="eg-button-group">
            {buttonGroup.map(
                (buttonProps: EGControlButtonProps, index: number) => (
                    <EGControlButton
                        key={index}
                        label={buttonProps.label}
                        actionType={buttonProps.actionType}
                        payload={buttonProps.payload}
                    />
                )
            )}
        </div>
    );
};
