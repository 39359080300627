interface CityRelativeCoordinatesTable {
    [city: string]: { xPercent: number, yPercent: number }
}

export const cityPercentOffsets: CityRelativeCoordinatesTable = {
    'Moscow': {
        xPercent: 18.15,
        yPercent: 40.0,
    },
    'Leningrad': {
        xPercent: 17.5,
        yPercent: 29.46,
    },
    'Stalingrad': {
        xPercent: 17.8,
        yPercent: 54.54,
    },
    'Kiev': {
        xPercent: 9.65,
        yPercent: 43.08,
    },
    'Odessa': {
        xPercent: 6.7,
        yPercent: 48.31,
    },
    'Gorki': {
        xPercent: 21.7,
        yPercent: 42.85,
    },
    'Ufa': {
        xPercent: 27.8,
        yPercent: 50.92,
    },
    'Smolensk': {
        xPercent: 14.05,
        yPercent: 38.23,
    },
    'Kurgan': {
        xPercent: 34.4,
        yPercent: 53.54,
    },
    'Omsk': {
        xPercent: 39.7,
        yPercent: 56.92,
    },
    'Novosibirsk': {
        xPercent: 46.7,
        yPercent: 56.15,
    },
    'Krasnoyarsk': {
        xPercent: 52.55,
        yPercent: 56.92,
    },
    'Tashkent': {
        xPercent: 32.65,
        yPercent: 78.92,
    },
    'Irkutsk': {
        xPercent: 61.15,
        yPercent: 62.38,
    },
    'Chita': {
        xPercent: 67.7,
        yPercent: 61.92,
    },
    'Yakutsk': {
        xPercent: 73.45,
        yPercent: 40.46,
    },
    'Samara': {
        xPercent: 23.9,
        yPercent: 50.92,
    },
    'Vladivostok': {
        xPercent: 84.75,
        yPercent: 69.62,
    },
    'Murmansk': {
        xPercent: 28.65,
        yPercent: 19.85,
    },
    'Frunze': {
        xPercent: 37.55,
        yPercent: 78.38,
    },
    'Alma-Ata': {
        xPercent: 39.55,
        yPercent: 77.69,
    },
    'Semipalatinsk': {
        xPercent: 42.3,
        yPercent: 64.85,
    },
    'Tbilisi': {
        xPercent: 13.3,
        yPercent: 66.0,
    },
    'Minsk': {
        xPercent: 11.4,
        yPercent: 36.62,
    },
}